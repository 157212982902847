import React from "react";
import koyoLogo from "../assets/icons/Koyo-Logo-desktop.svg";
import twitterLogo from "../assets/icons/x.svg";
import facebookLogo from "../assets/icons/faceBook.svg";
import igLogo from "../assets/icons/ig.svg";
import tiktokLogo from "../assets/icons/tiktok.svg";
import linkedInLogo from "../assets/icons/linkedin.svg";
import copyLogo from "../assets/icons/copy.svg";

interface SubmitModalProps {
  waitListNumber: number; // assuming waitListNumber is of type number
}

export const SubmitModal: React.FC<SubmitModalProps> = ({ waitListNumber }) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const link = "https://www.koyoapp.com/";
  return (
    <div
      className="modal fade"
      id="submitModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="submitModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title justify-content-center align-items-center"
              id="submitModalLabel"
            >
              <img src={koyoLogo} alt="Logo" />
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "inherit",
                padding: 0,
                boxShadow: "none",
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>Thank you! You are number {waitListNumber} on the waitlist</h5>
            <p className="modalText">
              We are excited because you get to be part of the success story of
              Koyo!
            </p>

            <br />
            <p className="followStyle">Follow us</p>
            <div className="d-flex justify-content-center w-100 mt-3">
              <a className="socialIcons">
                <img src={twitterLogo} alt="Logo" />
              </a>
              <a className="socialIcons">
                <img src={facebookLogo} alt="Logo" />
              </a>
              <a
                href=" https://www.instagram.com/koyoapp?igsh=eG1xbG0zeHc0Ynd6"
                className="socialIcons"
                target="_blank"
                rel="noreferrer"
              >
                <img src={igLogo} alt="Logo" />
              </a>
              <a className="socialIcons">
                <img src={tiktokLogo} alt="Logo" />
              </a>
              <a className="socialIcons">
                <img src={linkedInLogo} alt="Logo" />
              </a>
            </div>
          </div>
          <div className="modal-footer ">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn submitModalbtn"
                data-dismiss="modal"
                onClick={() => copyToClipboard(link)}
              >
                https://koyoapp.waitlist
                <img className="copyStyle" src={copyLogo} alt="copy" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
