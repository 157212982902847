import React from "react";
import "./loader.scss";
import koyoLogo from "../assets/icons/Koyo-Logo-desktop.svg";

export const Loader: React.FC = () => {
  return (
    <div className="loader">
      <img src={koyoLogo} alt="koyo logo" />
      <div className="spinner"></div>
    </div>
  );
};
