import React from "react";

interface InfoContentProps {
  image: string;
  title: string;
  text: string;
  bColor: string;
}

export const InfoContent: React.FC<InfoContentProps> = ({
  image,
  title,
  text,
  bColor
}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      
        <div className="card mb-3" style={{ backgroundColor: bColor, height:'30vh', width: '30vh', margin:10 }}>
          <div className="card-body">
            <img src={image} alt="" />
            <h5 className="card-title mt-3">{title}</h5>
            <p className="card-text">{text}</p>
          </div>
       
      </div>
    </div>
  )};

